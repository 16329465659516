<mat-card fxLayout="column" fxLayoutGap="0.5rem" fxFlexFill>
  <mat-card-header fxLayout="column" fxFlex.lt-md="100">
    <mat-card-title fxLayout="row" fxLayoutGap="0.5rem">
      <div fxHide.xs="true" fxLayout="column" fxLayoutAlign="center center" fxFlex.lt-sm="100">
        <a routerLink="{{routes?.base}}/{{routes?.view}}/{{card?.route}}" routerLinkActive="active-link">
          <img src="{{card?.imgpath}}" alt="{{card?.imgalt}}" style="width: auto; height: 5rem; object-fit: contain;">
        </a>
      </div>
    
      <div fxFlex *ngIf="card?.titles" fxLayout="column">
        <div *ngFor="let title of card?.titles" fxLayoutAlign="center">
          <span *ngIf="!title.hidden" class="font-1-5-rem" style="text-align: center;">{{title.text}}</span>
        </div>
      </div>
    </mat-card-title>

    <mat-card-subtitle *ngIf="card?.subtitles" fxLayout="column">
      <div *ngFor="let subtitle of card?.subtitles" fxLayoutAlign="center">
        <span *ngIf="!subtitle.hidden" class="font-1-5-rem" style="text-align: center;">{{subtitle.text}}</span>
      </div>
    </mat-card-subtitle>
  </mat-card-header>
  <!--
  <mat-card-header fxLayout="column" [ngStyle.gt-xs]="{'min-height': '9rem'}">
    <mat-card-title *ngIf="card?.titles" fxLayout="column">
      <div *ngFor="let title of card?.titles" fxLayoutAlign="center">
        <span *ngIf="!title.hidden" class="font-1-5-rem" style="text-align: center;">{{title.text}}</span>
      </div>
    </mat-card-title>
    <mat-card-subtitle *ngIf="card?.subtitles" fxLayout="column">
      <div *ngFor="let subtitle of card?.subtitles" fxLayoutAlign="center">
        <span *ngIf="!subtitle.hidden" class="font-1-5-rem" style="text-align: center;">{{subtitle.text}}</span>
      </div>
    </mat-card-subtitle>
  </mat-card-header>

  <div fxLayout="column" fxLayoutAlign="center center" fxFlex.lt-xl="100">
    <a routerLink="{{routes?.base}}/{{routes?.view}}/{{card?.route}}" routerLinkActive="active-link">
      <img src="{{card?.imgpath}}" alt="{{card?.imgalt}}" style="padding-top: 1rem; width: auto; height: 8rem; object-fit: contain;"
        [ngStyle.xs]="{'height': '5rem'}">
    </a>
  </div>
  -->

  <mat-card-content fxShow fxHide.lt-md="true" *ngIf="card?.content" fxFlex.gt-sm="100" fxLayout="column">
    <div *ngFor="let content of card?.content" 
      [ngStyle.gt-xs]="{'text-align': isAccentCard ? 'justify;' : 'left;'}" 
      [ngStyle.gt-sm]="{'text-align': 'justify;'}">
      <span class="font-1-3-rem">{{content}}</span>
    </div>
  </mat-card-content>

  <!--<mat-card-actions fxLayout="row wrap" fxLayoutGap="0.5rem grid" fxLayoutAlign="space-around" style="padding-bottom:  1rem;">-->
  <mat-card-actions fxLayout="row wrap" fxLayoutGap="0.5rem grid" fxLayoutAlign="space-around">
    <div *ngFor="let action of card?.actions" fxLayout="column" ngClass.lt-xl="icon-2-0-x">
      <div *ngIf="!action.hidden" fxLayout="column">
        <a fxShow fxHide.lt-xl="true" *ngIf="action.type==='route'" class="font-1-5-rem"
          routerLink="{{routes?.base}}/{{routes?.view}}/{{card?.route}}" routerLinkActive="active-link"
          mat-raised-button color="primary">{{action.text}}</a>
        <a fxFlex fxHide fxHide.lt-xl="false" *ngIf="action.type==='route'"
          routerLink="{{routes?.base}}/{{routes?.view}}/{{card?.route}}" routerLinkActive="active-link"
          mat-icon-button color="primary"><mat-icon>{{action.icon}}</mat-icon></a>

        <a fxShow fxHide.lt-xl="true" *ngIf="action.type==='tel'" class="font-1-5-rem" href="tel:{{action.tel}}"
          mat-raised-button color="primary">{{action.text}}</a>
        <a fxFlex fxHide fxHide.lt-xl="false" *ngIf="action.type==='tel'" class="font-1-5-rem" href="tel:{{action.tel}}"
          mat-icon-button color="primary"><mat-icon>{{action.icon}}</mat-icon></a>
            
        <a fxShow fxHide.lt-xl="true" *ngIf="action.type==='url'" class="font-1-5-rem" target="_blank" rel="noopener noreferrer" href="{{action.https}}"
          mat-raised-button color="primary">{{action.text}}</a>
        <a fxFlex fxHide fxHide.lt-xl="false" *ngIf="action.type==='url'" class="font-1-5-rem" target="_blank" rel="noopener noreferrer" href="{{action.https}}"
          mat-icon-button color="primary"><mat-icon>{{action.icon}}</mat-icon></a>
      </div>
    </div>
  </mat-card-actions>
</mat-card>