import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// configs
import { HOME_CONFIG, HomeConfig } from 'assets/configs/home.config';

// modules
import { SharedModule } from '~shared/shared.module';
const modules: any[] = [
  CommonModule,
  SharedModule
];

// components
import { HomeComponent } from './home.component';
const components: any[] = [
  HomeComponent
];
@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  providers: [
    {
      provide: HOME_CONFIG, useValue: HomeConfig
    }
  ]
})
export class HomeModule { }
