import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';

import { Router } from '@angular/router';
import { RoutesConfig } from 'assets/configs/routes.config';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit, OnDestroy {
  public routesNames = RoutesConfig.routesNames;

  @Output() sidenavClose = new EventEmitter();

/*
  constructor(private router: Router, mediaObserver: MediaObserver) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'xs') {
        this.onSidenavClose();
      }
    });
  }
*/
  constructor(private router: Router, private mediaObserver: MediaObserver) {}
  private mediaSubscription!: Subscription;
 
  ngOnInit(): void {
    this.mediaSubscription = this.mediaObserver
      .asObservable()
      .subscribe((change) => {
        change.forEach((item) => {
          if (item.mqAlias === 'gt-sm') {
            this.onSidenavClose();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.mediaSubscription.unsubscribe();
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }
}
