import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// modules
import { SharedModule } from '~shared/shared.module';
const modules: any[] = [
  RouterModule,
  SharedModule
];

// services
import { ThemeService } from './services/theme.service';
const services: any[] = [
  ThemeService
];

// components
import { HeaderComponent } from './components/header/header.component';
import { SidenavListComponent } from './components/sidenav-list/sidenav-list.component';
import { FooterComponent } from './components/footer/footer.component';
const components: any[] = [
  HeaderComponent,
  SidenavListComponent,
  FooterComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...components
  ],
  providers: [
    ...services
  ]
})
export class CoreModule { }
