import { Component, OnInit } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { Meta, Title} from '@angular/platform-browser';

import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLightTheme: Observable<boolean>;

  constructor(private themeService: ThemeService, private meta: Meta, private title: Title) {
    this.isLightTheme = observableOf(true);

    this.meta.addTags([
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'autobroker.bg' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' },
      { charset: 'utf-8'}
    ]);
    this.setTitle('autobroker.bg');
  }
  public setTitle (newTitle: string)
  {
    this.title.setTitle(newTitle);
  }

  ngOnInit() {
    this.isLightTheme = this.themeService.isLightTheme;
  }

  toggleLightTheme(checked: boolean) {
    this.themeService.setLightTheme(checked);
  }
}
