// system modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

// configs
import { ROUTES_CONFIG, RoutesConfig } from '../assets/configs/routes.config';

// custom modules
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
const modules: any[] = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  SharedModule,
  CoreModule,
  AppRoutingModule
];

// components
import { AppComponent } from './app.component';

// declare const require;

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ...modules,
  ],
  providers: [
    { provide: ROUTES_CONFIG, useValue: RoutesConfig }
],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
