import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoutesConfig } from 'assets/configs/routes.config';

// components
import { HomeComponent } from './pages/home/home.component';

// modules
import { ContactModule } from './pages/contact/contact.module';
import { HomeModule } from './pages/home/home.module';
const modules: any[] = [
  ContactModule,
  HomeModule
];

const routesNames = RoutesConfig.routesNames;
const routesConfig = RoutesConfig.routes;
const routes: Routes = [
  {
    path: routesConfig.carservice.path,
    loadChildren: () => import(`./pages/carservice/carservice.module`).then(m => m.CarserviceModule)
  },
  { path: routesConfig.home.path, component: HomeComponent },
//  { path: routesNames.routes[3].route, component: CarDealershipComponent },
  {
    path: routesConfig['car-dealership'].path,
    loadChildren: () => import(`./pages/car-dealership/car-dealership.module`).then(m => m.CarDealershipModule)
  },
  { path: '', redirectTo: routesConfig.home.route, pathMatch: 'full' },
  { path: '**', redirectTo: routesConfig.home.route, pathMatch: 'full' }
];

@NgModule({
  imports: [
    ...modules,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }),
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
