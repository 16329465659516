<mat-toolbar fxLayout="row" fxLayoutAlign="center center" color="primary">
  <div fxHide.gt-sm>
    <a mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </a>
  </div>

  <!--<div fxFlex.lt-md="50%" fxFlex.gt-md="15%" fxLayout fxLayoutAlign="end">-->
  
  <div fxFlex fxLayout fxLayoutAlign="start center" fxFlexFill fxLayoutAlign.lt-md="center center">
    <a routerLink="/"  routerLinkActive="active" style="height: 75%;" [ngStyle.xs]="{'height': '70%;'}">
      <img src="assets/AutoBroker-tb-i.png" alt="0885610810"
        style="height: 100%; width: auto; object-fit: contain" />
    </a>
  </div>

  <div fxFlex fxLayout fxLayoutAlign="end" fxHide.lt-md>
    <ul fxLayout fxLayoutGap="1rem" class="navigation-items">
      <div *ngFor="let route of (routesNames.routes | sort: 'position' | filterShow: 'visible')">  
        <li>
          <a routerLink="{{route.route}}" routerLinkActive="active-link">{{route.menuItem}}</a>
        </li>
      </div>
    </ul>
  </div>
</mat-toolbar>
