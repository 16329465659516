import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoutesConfig } from 'assets/configs/routes.config';

// components
import { ContactLayoutComponent } from './contact-layout.component';

const routesConfig = RoutesConfig.routes;
const contactRoutes: Routes = [
  { path: routesConfig.contact.path, component: ContactLayoutComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(contactRoutes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [ RouterModule ],
  declarations: []
})
export class ContactRoutingModule { }
