import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'sort'
})

@Injectable()
export class SortPipe implements PipeTransform {
  transform(items?: any[], field?: string): any[] {
    if (!items) { return []; }
    if (!field || field.length === 0) { return items; }
    const sortFn = (o1: any, o2: any) => o1[field] - o2[field];
    const items1: any[] = items.sort( sortFn );

    return items1;
  }
}
