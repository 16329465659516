<div 
  fxFlexFill 
  fxFlex.gt-xs="calc(100%+0.5rem)" fxLayout="row wrap" fxLayoutGap.gt-xs="0.5rem grid" 
  fxLayout.xs="column" fxLayoutGap.xs="0.5rem"
  fxLayoutAlign="center">
  <div 
    fxFlex.gt-xs="100"
    fxFlex.gt-sm="0 0 calc(50%)"
    fxFlex.gt-md="0 0 calc(33.3%)" 
    *ngFor="let card of (cards | sort: 'position' | filterShow: 'show': 'route': accentCardRoute)">
    <app-card-view [card]="card" [routes]="routes"></app-card-view>
  </div>
</div>
