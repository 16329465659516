<div fxFlex="100" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center">  
  <div 
    fxFlex="100"
    fxFlex.gt-sm="0 0 calc(90%)" 
    fxLayout="row wrap" 
    fxLayout.xs="column"
    fxLayoutGap="0.5rem grid" 
    fxLayoutAlign="center"
  >
    <div
      fxFlex="100" 
      fxFlex.gt-sm="0 0 calc(50%)"
      fxLayout="column"
      *ngFor="let card of (homeConfig | sort: 'position' | filterShow: 'show')"
    >
      <mat-card fxFlex="100">
        <mat-card-content fxLayout="row" fxLayoutAlign="center center">
          <div fxLayout="column">
          <a *ngIf="card?.type==='route'"
            routerLink="{{card?.link}}" routerLinkActive="active-link"
          >
            <img src="{{card?.image}}" alt="{{card?._alt}}"
            style="max-height: 4.7rem; height: 100%; width: auto; object-fit: contain"
            [ngStyle.xs]="{'max-height': '2.7rem;'}" />
          </a>

          <a *ngIf="card?.type==='url'"
            target="_blank" rel="noopener noreferrer"
            href="https://{{card?.link}}"
          >
            <img src="{{card?.image}}" alt="{{card?._alt}}" 
            style="max-height: 4.7rem; height: 100%; width: auto; object-fit: contain"
            [ngStyle.xs]="{'max-height': '2.7rem;'}" />
          </a>
        </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>