import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RoutesConfig } from 'assets/configs/routes.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public routesNames = RoutesConfig.routesNames;

  @Output() public sidenavToggle = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
}
