import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accent-cards-layout',
  templateUrl: './accent-cards-layout.component.html',
  styleUrls: ['./accent-cards-layout.component.scss']
})
export class AccentCardsLayoutComponent implements OnInit {
  @Input() cards: any;
  @Input() routes: any;

  constructor() { }

  ngOnInit() {
  }
}
