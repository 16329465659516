import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// configs
import { CONTACT_CONFIG, ContactConfig } from 'assets/configs/contact.config';

// modules
import { SharedModule } from '~shared/shared.module';
import { InquiryModule } from '~shared/components/inquiry/inquiry.module';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { ContactRoutingModule } from './contact-routing.module';
const modules: any[] = [
  CommonModule,
  SharedModule,
  InquiryModule,
  AgmCoreModule.forRoot({ apiKey: 'AIzaSyB7M70w2liahFGBYI6wfZgz7hx8icncWSY' }),
  AgmSnazzyInfoWindowModule,
  ContactRoutingModule
];

// components
import { ContactLayoutComponent } from './contact-layout.component';
import { MapComponent } from './map/map.component';
import { ContactComponent } from './contact/contact.component';
const components: any[] = [
  ContactLayoutComponent,
  MapComponent,
  ContactComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  providers: [
    { provide: CONTACT_CONFIG, useValue: ContactConfig }
  ]
})
export class ContactModule { }
