import { InjectionToken } from '@angular/core';

import { RoutesConfig } from './routes.config';
const routes = RoutesConfig.routes;

export let HOME_CONFIG = new InjectionToken('home.config');

export const HomeConfig: any = [
  {
    position: 1,
    show: true,
    image: 'assets/AutoBroker-tb.png',
    _alt: 'Автоброкер',
    type: 'route',
    link: routes.contact.route
  },
  {
    position: 2,
    show: true,
    image: 'assets/carservice/iAutoService-tb.png',
    _alt: 'iAutoService',
    type: 'route',
    link: routes.carservice.route
  },
  {
    position: 3,
    show: true,
    image: 'assets/car-dealership/iAuto-tb.png',
    _alt: 'iAuto',
    type: 'url',
    link: 'iauto.mobile.bg'
  },
  {
    position: 4,
    show: true,
    image: 'assets/iLeasing-tb.png',
    _alt: 'iLeasing',
    type: 'url',
    link: 'ileasing.eu'
  }
];

