import { Component, OnInit, ViewChild } from '@angular/core';
import { Meta, Title} from '@angular/platform-browser';

import { HomeConfig } from 'assets/configs/home.config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  homeConfig = HomeConfig;

  constructor(private meta: Meta, private title: Title) {
    this.meta.updateTag({ name: 'description', content: 'комплекс АвтоБрокер - всичко за автомобила' });
    this.meta.updateTag({ property: 'og:description', content: 'комплекс АвтоБрокер - всичко за автомобила на autobroker.bg' });
    this.meta.updateTag({ name: 'keywords', content: 'AutoBroker, АвтоБрокер, iAuto, иАуто, iAutoService, иАутоСервиз, автосервиз' });
    this.setTitle('комплекс АвтоБрокер - всичко за автомобила');
  }
  public setTitle (newTitle: string)
  {
    this.title.setTitle(newTitle);
  }

  ngOnInit() { }
}
