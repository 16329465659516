<div fxFlex fxLayout="column">
  <b class="font-2-0-rem" fxLayout="row" fxLayoutAlign="center" style="padding-bottom: 2rem;"
    fxHide.lt-md>{{mapData.map.title}}</b>

  <agm-map [latitude]="mapData.map.lat" [longitude]="mapData.map.long" [zoom]="17"
  [ngStyle.gt-sm]="{'height': '100%'}">
    <agm-marker [latitude]="mapData.map.lat" [longitude]="mapData.map.long">
      <!-- как да сменям backgroundColor="black" в зависимост от темата -->
      <agm-snazzy-info-window [maxWidth]="600" maxHeight="600px" [closeWhenOthersOpen]="true" [closeOnMapClick]="true"
        backgroundColor="black">
        <ng-template>
          <div fxLayout="column" fxLayoutAlign="start center">
            <b fxFlex class="font-1-3-rem" fxLayout="row" fxLayoutAlign="center"
              style="padding-bottom: 0.8rem;">{{mapData.name}}</b>

            <div *ngFor="let phone of mapData.phones">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="icon-1-0-x" style="padding-right: 0.3rem">{{phone.itemIcon}}</mat-icon>
                <div class="font-1-0-rem">
                  <b>{{phone.name}}</b>
                  <br />
                  <a href="tel:{{phone.phone}}" style="color: greenyellow;">{{phone.phone}}</a>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </agm-snazzy-info-window>
    </agm-marker>
  </agm-map>
</div>
