import { Component, OnInit } from '@angular/core';
import { Meta, Title} from '@angular/platform-browser';

import { ContactConfig } from 'assets/configs/contact.config';

@Component({
  selector: 'app-contact-layout',
  templateUrl: './contact-layout.component.html',
  styleUrls: ['./contact-layout.component.scss']
})
export class ContactLayoutComponent implements OnInit {
  contactConfig = ContactConfig;
  mapData: any = {
    map: this.contactConfig.map,
    name: this.contactConfig.name,
    phones: this.contactConfig.phones
  }
  contactData: any = {
    name: this.contactConfig.name,
    address: this.contactConfig.address,
    phones: this.contactConfig.phones,
    contactIcon: this.contactConfig.contactIcon,
    contactName: this.contactConfig.contactName,
    email: this.contactConfig.email,
    worktime: this.contactConfig.worktime
  }

  constructor(private meta: Meta, private title: Title) {
    this.meta.updateTag({ name: 'description', content: 'комплекс АвтоБрокер - всичко за автомобила' });
    this.meta.updateTag({ property: 'og:description', content: 'комплекс АвтоБрокер - всичко за автомобила на autobroker.bg' });
    this.meta.updateTag({ name: 'keywords', content: 'контакти, ул. Обиколна №37, 0893448080, 0885610810, 0886900306' });
    this.setTitle('контакти - комплекс АвтоБрокер');
  }
  public setTitle (newTitle: string)
  {
    this.title.setTitle(newTitle);
  }

  ngOnInit() {
  }
}
