<div fxFlex fxLayout="column">
  <b class="font-2-0-rem" fxLayout="row" fxLayoutAlign="center" style="padding-bottom: 2rem;"
    fxHide.lt-md>{{contactData.name}}</b>

  <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 1rem">
    <mat-icon class="icon-1-0-x" ngClass.gt-sm="icon-1-3-x" style="padding-right: 0.3rem" color="primary">
      {{contactData.address.itemIcon}}</mat-icon>
    <span>{{contactData.address.itemName}}</span>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 1rem">
    <mat-icon class="icon-1-0-x" ngClass.gt-sm="icon-1-3-x" style="padding-right: 0.3rem" color="primary">
      {{contactData.email.itemIcon}}</mat-icon>
    <a href="mailto:{{contactData.email.email}}">{{contactData.email.email}}</a>
  </div>

  <div fxShow fxHide.xs="true" fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 0.5rem;">
    <mat-icon class="icon-1-0-x" ngClass.gt-sm="icon-1-3-x" style="padding-right: 0.3rem" color="primary">
      {{contactData.contactIcon}}</mat-icon>
    <span>{{contactData.contactName}}</span>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="0.5rem grid" fxLayout.xs="column" fxLayoutAlign="center" style="padding-bottom: 1rem;">
    <div fxFlex.xs="100" fxFlex.gt-xs="0 0 calc(50%)" fxFlex.gt-md="0 0 calc(33.3%)" fxFlex.gt-lg="0 0 calc(25%)" fxLayout="row wrap"
      *ngFor="let phone of contactData.phones | filterShow: 'show'">
      <mat-card fxLayout="column" fxFlexFill>
        <mat-card-header fxLayoutAlign="center start" [ngStyle.gt-xs]="{'min-height': '50px;'}" [ngStyle.gt-lg]="{'min-height': '70px;'}">
          <mat-card-title>
            <div fxShow fxHide.xs="true" fxFlex fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 0.5rem">
              <b class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem">{{phone.name}}</b>
            </div>
            
            <div *ngIf="phone?.image" fxFlex fxLayout="row" fxLayoutAlign="center end">
              <a *ngIf="phone.image?.type==='route'"
                routerLink="{{phone.image?.link}}" routerLinkActive="active-link"
              >
                <img src="{{phone.image?.path}}" alt="{{phone.image?._alt}}" 
                style="height: auto; width: 100%; object-fit: contain" />
              </a>
    
              <a *ngIf="phone.image?.type==='url'"
                target="_blank" rel="noopener noreferrer"
                href="https://{{phone.image?.link}}"
              >
                <img src="{{phone.image?.path}}" alt="{{phone.image?._alt}}" 
                style="height: auto; width: 100%; object-fit: contain" />
              </a>
            </div>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content fxFlex="100">
          <div *ngIf="phone.worktime?.show">
            <div fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 0.5rem;">
              <mat-icon class="icon-1-0-x" ngClass.gt-sm="icon-1-3-x" style="padding-right: 0.3rem" color="primary">
                {{contactData.worktime.itemIcon}}</mat-icon>
              <span class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem">{{contactData.worktime.itemName}}</span>
            </div>

            <div fxLayout="row" *ngFor="let text of phone.worktime.text">
              <span class="font-1-0-rem" ngClass.gt-sm="font-1-3-rem" fxHide="{{text.hidden}}">{{text.text}}</span>
            </div>
          </div>
        </mat-card-content>

        <mat-card-actions fxLayout="row wrap" fxLayoutGap="0.5rem grid" fxLayoutAlign="space-around" style="padding-bottom:  1rem;">
          <div fxShow fxHide.lt-xl="true" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="icon-1-0-x" ngClass.gt-sm="icon-1-3-x" style="padding-right: 0.3rem" color="primary">
              {{phone.itemIcon}}</mat-icon>
            <a class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem" href="tel:{{phone.phone}}">{{phone.phone}}</a>
          </div>
          <div fxHide fxHide.lt-xl="false" fxLayout="row" fxLayoutAlign="start center">
            <a ngClass.lt-xl="icon-2-0-x" href="tel:{{phone.phone}}" mat-icon-button color="primary"><mat-icon>{{phone.itemIcon}}</mat-icon></a>
          </div>

          <div fxShow fxHide.lt-xl="true" fxLayout="row" fxLayoutAlign="start center" *ngIf="phone.web" style="padding-top: 0.3rem">
            <mat-icon class="icon-1-0-x" ngClass.gt-sm="icon-1-3-x" style="padding-right: 0.3rem" color="primary">
              {{phone.web.itemIcon}}</mat-icon>
            <a class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem" target="_blank" rel="noopener noreferrer"
              href="https://{{phone.web.www}}">{{phone.web.www}}</a>
          </div>
          <div fxHide fxHide.lt-xl="false" fxLayout="row" fxLayoutAlign="start center" *ngIf="phone.web" style="padding-top: 0.3rem">
            <a ngClass.lt-xl="icon-2-0-x" target="_blank" rel="noopener noreferrer"
              href="https://{{phone.web.www}}" mat-icon-button color="primary"><mat-icon>{{phone.web.itemIcon}}</mat-icon></a>
          </div>

          <div fxShow fxHide.lt-xl="true" fxLayout="row" fxLayoutAlign="center center" *ngIf="phone.url" style="padding-top: 0.3rem">
            <a class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem" target="_blank" rel="noopener noreferrer"
              href="{{phone.url.https}}" mat-raised-button color="primary">{{phone.url.text}}</a>
          </div>
          <div fxHide fxHide.lt-xl="false" fxLayout="row" fxLayoutAlign="center center" *ngIf="phone.url" style="padding-top: 0.3rem">
            <a ngClass.lt-xl="icon-2-0-x" target="_blank" rel="noopener noreferrer"
              href="{{phone.url.https}}" mat-icon-button color="primary"><mat-icon>{{phone.url.icon}}</mat-icon></a>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <!--
  <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 0.5rem;">
    <mat-icon class="icon-1-0-x" ngClass.gt-sm="icon-1-3-x" style="padding-right: 0.3rem" color="primary">
      {{contactData.worktime.itemIcon}}</mat-icon>
    <span>{{contactData.worktime.itemName}}</span>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="0.5rem grid" fxLayout.xs="column">
    <div fxFlex.xs="100" fxFlex.gt-xs="1 0 300px" fxFlex.gt-lg="1 0 500px" fxLayout="row"
      *ngFor="let worktime of contactData.worktime.rows | filterShow: 'show'">
      <mat-card fxLayout="column" fxFlexFill>
        <mat-card-header fxLayoutAlign="start">
          <mat-card-title>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="icon-1-0-x" ngClass.gt-sm="icon-1-3-x" style="padding-right: 0.3rem" color="primary">
                {{contactData.worktime.itemIcon}}</mat-icon>
              <b class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem">{{worktime.name}}</b>
            </div>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div fxLayout="row" *ngFor="let text of worktime.text">
            <span class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem" fxHide="{{text.hidden}}">{{text.text}}</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  -->
</div>