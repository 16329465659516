import { NgModule } from '@angular/core';

// import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
// import { AppDateAdapter, APP_DATE_FORMATS } from './format-datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
/*
import {
  MAT_DATE_LOCALE,
  MatToolbarModule,
  MatSlideToggleModule,
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatSidenavModule,
  MatCardModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule
} from '@angular/material';
*/
const modules: any[] = [
  MatToolbarModule,
  MatSlideToggleModule,
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatSidenavModule,
  MatCardModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule
];

@NgModule({
  declarations: [],
  imports: [
    ...modules
  ],
  exports: [
    ...modules
  ],
  providers: [
//    { provide: DateAdapter, useClass: AppDateAdapter },
//    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'bg-BG' }
  ]
})
export class MaterialModule { }
