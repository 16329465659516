import { InjectionToken } from '@angular/core';

export let INQUIRY_CONFIG = new InjectionToken('inquiry.config');

const minLength = 2;
const maxLength = 30;
export const InquiryConfig: any = {
  title: 'Изпрате запитване',
  required: 'Задължително поле',
  name: {
    placeholder: 'Име',
    hint: 'Попълнете име',
    min_length: minLength,
    min_err_hint: 'Минимум ' + minLength + ' символа',
    max_length: maxLength,
    max_err_hint: 'Максимум ' + maxLength + ' символа'
  },
  lastname: {
    placeholder: 'Фамилия',
    hint: 'Попълнете фамилия',
    min_length: minLength,
    min_err_hint: 'Минимум ' + minLength + ' символа',
    max_length: maxLength,
    max_err_hint: 'Максимум ' + maxLength + ' символа'
  },
  phone: {
    placeholder: 'Телефонен номер',
    hint: 'Попълнете телефонен номер',
    wrongNumber_err_hint: 'Попълнете валиден телефонен номер',
    min_length: minLength,
    max_length: maxLength,
    max_err_hint: 'Максимум ' + maxLength + ' символа'
  },
  email: {
    placeholder: 'e-mail',
    hint: 'Попълнете e-mail',
    email_err_hint: 'Попълнете валиден e-mail',
    min_length: minLength,
    max_length: maxLength,
    max_err_hint: 'Максимум ' + maxLength + ' символа'
  },
  contactto: {
    label: 'Предпочитан контакт:',
    hint: 'Изберете как да се свържем с Вас',
    items: [
      { value: 'email', viewValue: 'email'},
      { value: 'phone', viewValue: 'Телефон'}
    ]
  },
  sendto: {
    label: 'До:',
    hint: 'Изберете получател на съобщението',
    items: [
      { value: 'office', viewValue: 'Офис'},
      { value: 'iAuto', viewValue: 'Автокъща'},
      { value: 'iLeasing', viewValue: 'Лизинг'},
      { value: 'iAutoService', viewValue: 'Автосервиз'}
    ]
  },
  message: {
    placeholder: 'Съобщение',
    hint: 'Напишете Вашето съобщение',
    min_length: 5,
    min_err_hint: 'Минимум 5 символа',
    max_length: 500,
    max_err_hint: 'Максимум 500 символа',
    min_rows: 2,
    max_rows: 5
  },
  agreement: {
    text: 'Съгласявам се с Условията за защита на личните данни'
  },
  submitButton: {
    text: 'Изпрати'
  },
  submitted: {
    text: 'Вашето съобщение беше изпратено.'
  },
  car: {
    mark: {
      placeholder: 'Марка',
      hint: 'Попълнете марка',
      min_length: minLength,
      min_err_hint: 'Минимум ' + minLength + ' символа',
      max_length: maxLength,
      max_err_hint: 'Максимум ' + maxLength + ' символа'
    },
    model: {
      placeholder: 'Модел',
      hint: 'Попълнете модел',
      min_length: minLength,
      min_err_hint: 'Минимум ' + minLength + ' символа',
      max_length: maxLength,
      max_err_hint: 'Максимум ' + maxLength + ' символа'
    },
    fuel: {
      label: 'Гориво:',
      hint: 'Изберете вида гориво',
      items: [
        { value: 'gasoline', viewValue: 'бензин'},
        { value: 'diesel', viewValue: 'дизел'},
        { value: 'gas', viewValue: 'бензин/газ'},
        { value: 'hybrid', viewValue: 'хибрид'},
        { value: 'electric', viewValue: 'електрическа'}
      ]
    },
    transmission: {
      label: 'Скоростна кутия:',
      hint: 'Изберете вида скоростна кутия',
      items: [
        { value: 'automatic', viewValue: 'автоматична'},
        { value: 'manual', viewValue: 'ръчна'}
      ]
    },
    firstRegistration: {
      label: 'Първа регистрация',
      hint: 'Изберете годината на регистрация',
      carYears: 10
    },
    price: {
      placeholder: 'Желана цена',
      hint: 'Попълнете желаната цена',
      min_length: minLength,
      min_err_hint: 'Минимум ' + minLength + ' символа',
      max_length: maxLength,
      max_err_hint: 'Максимум ' + maxLength + ' символа'
    }
  }
};

export interface Inquiry {
  name?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  contactto?: string;
  sendto?: string;
  message?: string;
  agreement?: boolean;
}

export interface CarData {
  mark?: string;
  model?: string;
  fuel?: string;
  transmission?: string;
  firstRegistration?: number;
  price?: string;
}

