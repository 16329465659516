<!--<tui-root>-->
<div [ngClass]="{'light-theme': isLightTheme | async}">
  <div class="mat-app-background">
    <mat-sidenav-container>
      <mat-sidenav #sidenav role="navigation">
        <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
      </mat-sidenav>

      <mat-sidenav-content>
        <div fxLayout="column" style="height:100vh;">
          <app-header (sidenavToggle)="sidenav.toggle()"></app-header>

          <div style="padding: 1rem;">
            <router-outlet></router-outlet>
          </div>

          <app-footer fxFlexOffset="auto"></app-footer>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
<!--</tui-root>-->