import { InjectionToken } from '@angular/core';

export let ROUTES_CONFIG = new InjectionToken('routes.config');

/*
const basePaths = {
  heroes: 'heroes',
};
*/

const routesNames = {
  routes: [
    {
      position: 10,
      visible: true,
      route: 'contact',
      menuItem: 'Контакти',
      menuIcon: 'contacts'
    },
    {
      position: 9,
      visible: true,
      route: 'carservice',
      menuItem: 'Автосервиз',
      menuIcon: 'build'
    },
    {
      position: 8,
      visible: false,
      route: 'home',
      menuItem: 'Начало',
      menuIcon: 'home'
    },
    {
      position: 7,
      visible: false,
      route: 'car-dealership',
      menuItem: 'Автокъща',
      menuIcon: 'directions_car'
    }
  ]
/*
  error404: '404',
  heroes: {
    basePath: basePaths.heroes
  }
*/
};

export const RoutesConfig: any = {
  routesNames,
  routes: {
    'contact': {
      path: routesNames.routes[0].route,
      route: `/${routesNames.routes[0].route}`
    },
    'carservice': {
      path: routesNames.routes[1].route,
      route: '/' + routesNames.routes[1].route
    },
    'home': {
      path: routesNames.routes[2].route,
      route: `/${routesNames.routes[2].route}`
    },
    'car-dealership': {
      path: routesNames.routes[3].route,
      route: `/${routesNames.routes[3].route}`
    }
/*
    error404: `/${routesNames.error404}`,
    heroes: {
      detail: getHeroDetail
    }
*/
  }
};

/*
export function getHeroDetail(id) {
  return `/${basePaths.heroes}/${id}`;
}
*/

