import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cards-layout',
  templateUrl: './cards-layout.component.html',
  styleUrls: ['./cards-layout.component.scss']
})
export class CardsLayoutComponent implements OnInit {
  @Input() cards: any;
  @Input() accentCardRoute = '';
  @Input() routes: any;

  constructor() { }

  ngOnInit() {
  }
}
