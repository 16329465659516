import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// modules
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './modules/material.module';
const modules: any[] = [
  CommonModule,
  RouterModule,
  FlexLayoutModule,
  MaterialModule
];

// pipes
import { FilterShowPipe } from './pipes/filter-show.pipe';
import { SortPipe } from './pipes/sort.pipe';
const pipes: any[] = [
  FilterShowPipe,
  SortPipe
];

// services

// components
import { CardsLayoutComponent } from './components/cards-layout/cards-layout.component';
import { AccentCardsLayoutComponent } from './components/accent-cards-layout/accent-cards-layout.component';
import { CardViewComponent } from './components/card-view/card-view.component';
import { CardPageComponent } from './components/card-page/card-page.component';
import { ReservationFormComponent } from './components/reservation-form/reservation-form.component';
const components: any[] = [
  CardsLayoutComponent,
  AccentCardsLayoutComponent,
  CardViewComponent,
  CardPageComponent,
  ReservationFormComponent
];

@NgModule({
  declarations: [
    ...pipes,
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components,
    ...pipes
  ],
  providers: []
})
export class SharedModule { }
