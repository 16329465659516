import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// configs
import { INQUIRY_CONFIG, InquiryConfig } from 'assets/configs/inquiry.config';

import { SharedModule } from '~shared/shared.module';
const modules: any[] = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
  SharedModule
];

import { InquiryComponent } from './inquiry.component';
const components: any[] = [
  InquiryComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...components
  ],
  providers: [
    { provide: INQUIRY_CONFIG, useValue: InquiryConfig }
  ]
})
export class InquiryModule { }
