import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'filterShow'
})

@Injectable()
export class FilterShowPipe implements PipeTransform {
  transform(items?: any[], field?: string, field1?: string, value?: any): any[] {
    if (!items) { return []; }
//    const sortFn = (o1: any, o2: any) => o1.position - o2.position;
//    const items1: any[] = items.sort( sortFn );

    if (!field || field.length === 0) { return items; }
    if (!field1 || field1.length === 0 || !value || value.length === 0) { return items.filter(it => it[field]); }

    return items.filter(it => it[field] && it[field1] !== value);
  }
}
