<div fxLayout="column" fxFlexFill fxLayoutGap="1rem" class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem">
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="center" fxFlexFill>
    <app-contact fxFlex [contactData]="contactData"></app-contact>
  </div>

  <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="1rem">
    <div fxLayout="column" fxFlex.gt-sm="38%">
      <app-map fxFlex [mapData]="mapData"></app-map>
    </div>

    <div fxLayout="column" fxFlex.gt-sm="62%">
      <app-inquiry></app-inquiry>
    </div>
  </div>
</div>


<!--
<div fxLayout="column" fxLayout.gt-md="row" fxFlexFill fxLayoutGap="1rem" class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem">
  <div fxFlex.gt-md="62%" fxLayoutGap="1rem" fxLayout="column" fxLayout.gt-sm="row">
    <div fxFlex.gt-sm="50%" fxLayout="column" fxFlexOrder.lt-md="1">
      <app-map fxFlex [mapData]="mapData"></app-map>
    </div>

    <div fxLayout="column" fxFlex.gt-sm="50%" fxFlexOrder.lt-md="0">
      <app-contact [contactData]="contactData"></app-contact>
    </div>
  </div>

  <div fxFlex.gt-md="38%" fxLayout="column">
    <app-inquiry></app-inquiry>
  </div>
</div>
-->
