import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, NgForm } from '@angular/forms';
import { timer } from 'rxjs';

import { InquiryConfig, Inquiry, CarData } from 'assets/configs/inquiry.config';
import { PhoneNumberValidator } from '~shared/validators/phone.validator';

const emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {
  @Input() carInquiry: boolean = false;
  @Input() typeCarInquiry: string = '';

  inquiryConfig = InquiryConfig;
  inquiry: Inquiry | undefined;
  cardata: CarData | undefined;
  carYearItems: any[] | undefined;
  inquiryForm: UntypedFormGroup | undefined;
  cbIndeterminate: boolean = true;
  submitted: boolean = false;
  get controlName() { return this.inquiryForm?.get('name'); }
  get controlLastName() { return this.inquiryForm?.get('lastname'); }
  get controlPhone() { return this.inquiryForm?.get('phone'); }
  get controlEmail() { return this.inquiryForm?.get('email'); }
  get controlContactTo() { return this.inquiryForm?.get('contactto'); }
  get controlSendTo() { return this.inquiryForm?.get('sendto'); }
  get controlMessage() { return this.inquiryForm?.get('message'); }
  get controlAgreement() { return this.inquiryForm?.get('agreement'); }
  get controlCarMark() { return this.inquiryForm?.get('carMark'); }
  get controlCarModel() { return this.inquiryForm?.get('carModel'); }
  get controlCarFuel() { return this.inquiryForm?.get('carFuel'); }
  get controlCarFirstRegistration() { return this.inquiryForm?.get('carFirstRegistration'); }
  get controlCarPrice() { return this.inquiryForm?.get('carPrice'); }

  constructor() {
    // this.inquiry = undefined;
    // this.cardata = undefined;
    this.resetInquiry();
    this.carYearItems = undefined;
    this.inquiryForm = undefined;
    this.submitted = false;
    // this.cbIndeterminate = true;
  }

  ngOnInit(): void {
    const y = +(new Date()).getFullYear();
    const a = [];
    for ( let i = 0; i < this.inquiryConfig.car.firstRegistration.carYears; i++ ) {
      a.push( { value: y - i, viewValue: y - i + ' г.'} );
    }
    this.carYearItems = a;
    this.setSubmit(false);
    this.resetInquiry();

    this.inquiryForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(this.inquiryConfig.name.min_length),
        Validators.maxLength(this.inquiryConfig.name.max_length)
      ]),
      lastname: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(this.inquiryConfig.lastname.min_length),
        Validators.maxLength(this.inquiryConfig.lastname.max_length)
      ]),
      phone: new UntypedFormControl('', [
        Validators.required,
        PhoneNumberValidator('BG')
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(emailPattern),
        Validators.email
      ]),
      contactto: new UntypedFormControl('', [
        Validators.required
      ]),
      sendto: new UntypedFormControl({value: '', disabled: this.carInquiry}, [
        Validators.required
      ]),
      message: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(this.inquiryConfig.message.min_length),
        Validators.maxLength(this.inquiryConfig.message.max_length)
      ]),
      agreement: new UntypedFormControl('', [
        Validators.required
      ]),
      carMark: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(this.inquiryConfig.car.mark.min_length),
        Validators.maxLength(this.inquiryConfig.car.mark.max_length)
      ]),
      carModel: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(this.inquiryConfig.car.model.min_length),
        Validators.maxLength(this.inquiryConfig.car.model.max_length)
      ]),
      carFuel: new UntypedFormControl('', [
        Validators.required
      ]),
      carTransmission: new UntypedFormControl('', [
        Validators.required
      ]),
      carFirstRegistration: new UntypedFormControl('', [
        Validators.required
      ]),
      carPrice: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(this.inquiryConfig.car.price.min_length),
        Validators.maxLength(this.inquiryConfig.car.price.max_length)
      ])
    });

    if ( !this.carInquiry ) {
      this.inquiryForm?.get('carMark')?.clearValidators();
      this.inquiryForm?.get('carModel')?.clearValidators();
      this.inquiryForm?.get('carFuel')?.clearValidators();
      this.inquiryForm?.get('carTransmission')?.clearValidators();
      this.inquiryForm?.get('carFirstRegistration')?.clearValidators();
      this.inquiryForm?.get('carPrice')?.clearValidators();

      this.inquiryForm?.updateValueAndValidity();
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.inquiryForm?.controls[controlName]?.hasError(errorName);
  }

  resetInquiry = () => {
    this.inquiry = {
      name: '',
      lastname: '',
      phone: '',
      email: '',
      contactto: 'email',
      sendto: this.carInquiry ? 'iAuto' : 'office',
      message: '',
      agreement: undefined
    };
    this.cardata = {
      mark: '',
      model: '',
      fuel: 'gasoline',
      transmission: 'automatic',
      firstRegistration: undefined,
      price: ''
    }
    this.cbIndeterminate = true;
  }

  public sendInquiry = () => {
    console.log(this.inquiry);
    console.log(this.cardata);
    if (this.inquiryForm?.valid && !this.submitted) {
      this.setSubmit(true);
      const ok = timer(60000);
      ok.subscribe( x => this.setSubmit(false) );
      this.inquiryForm?.markAsPristine();
      this.inquiryForm?.reset();
      this.resetInquiry();
      console.log(this.inquiry);
      console.log(this.cardata);
    }
  }

  private setSubmit = (s: boolean) => {
    this.submitted = s;
  }
}
