<mat-card fxLayout="column" fxLayoutGap="0.5rem" fxFlexFill>
  <mat-card-header fxLayout="column">
    <div fxFlex fxLayout="row">
      <div *ngIf="card?.imgpath" fxHide.xs="true" [ngStyle.gt-xs]="{'position': 'absolute;'}">
        <img src="{{card?.imgpath}}" alt="{{card?.imgalt}}"
          [ngStyle.lt-xl]="{'max-height': '4rem'}"
          style="height: 100%; width: auto; max-height: 8rem; object-fit: contain">
      </div>

      <div fxFlex fxLayout="column">
        <mat-card-title *ngIf="card?.titles">
          <div *ngFor="let title of card?.titles" fxLayoutAlign="center">
            <span *ngIf="!title.hidden" ngClass.gt-xs="font-2-0-rem" ngClass.xs="font-1-5-rem"
              [ngStyle.gt-xs]="{'padding-bottom': '0.5rem'}" style="text-align: center;">
              {{title.text}}
            </span>
          </div>
        </mat-card-title>

        <mat-card-subtitle *ngIf="card?.subtitles" style="height: 100%;">
          <div *ngFor="let subtitle of card?.subtitles" fxLayoutAlign="center">
            <span *ngIf="!subtitle.hidden" ngClass.gt-xs="font-2-0-rem" ngClass.xs="font-1-5-rem"
              [ngStyle.gt-xs]="{'padding-bottom': '0.5rem'}" style="text-align: center;">
              {{subtitle.text}}
            </span>
          </div>
        </mat-card-subtitle>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content fxLayout="column">
    <ng-content></ng-content>
  </mat-card-content>

  <mat-card-actions fxLayout="row wrap" fxLayoutGap="0.5rem grid" fxLayoutAlign="space-around" style="padding-bottom:  1rem;">
    <div ngClass.lt-xl="icon-2-0-x" [ngStyle.gt-md]="{'min-height': '2rem;'}">
      <a fxShow fxHide.lt-xl="true" ngClass.gt-xs="font-2-0-rem" ngClass.xs="font-1-5-rem" routerLink="{{routes?.base}}/{{routes?.list}}"
        routerLinkActive="active-link" mat-raised-button color="primary">
        {{routes?.backtext}}
      </a>
      <a fxFlex fxHide fxHide.lt-xl="false" routerLink="{{routes?.base}}/{{routes?.list}}"
        routerLinkActive="active-link" mat-icon-button color="primary">
        <mat-icon>{{routes?.backIcon}}</mat-icon>
      </a>
    </div>
    <div *ngFor="let action of card?.actions" ngClass.lt-xl="icon-2-0-x">
      <div fxLayoutAlign="center">
        <a fxShow fxHide.lt-xl="true" *ngIf="action.type==='tel'" ngClass.gt-xs="font-2-0-rem" ngClass.xs="font-1-5-rem" href="tel:{{action.tel}}" mat-raised-button color="primary">
          {{action.text}}
        </a>
        <a fxFlex fxHide fxHide.lt-xl="false" *ngIf="action.type==='tel'" href="tel:{{action.tel}}" mat-icon-button color="primary">
          <mat-icon>{{action.icon}}</mat-icon>
        </a>

        <a fxShow fxHide.lt-xl="true" *ngIf="action.type==='url'" ngClass.gt-xs="font-2-0-rem" ngClass.xs="font-1-5-rem" target="_blank" rel="noopener noreferrer" href="{{action.https}}" mat-raised-button color="primary">
          {{action.text}}
        </a>
        <a fxFlex fxHide fxHide.lt-xl="false" *ngIf="action.type==='url'" target="_blank" rel="noopener noreferrer" href="{{action.https}}" mat-icon-button color="primary">
          <mat-icon>{{action.icon}}</mat-icon>
        </a>
      </div>
    </div>
  </mat-card-actions>
</mat-card>