import { InjectionToken } from '@angular/core';

import { RoutesConfig } from './routes.config';
const routes = RoutesConfig.routes;

export let CONTACT_CONFIG = new InjectionToken('contact.config');

export const ContactConfig: any = {
  map: {
    title: 'Къде сме ние',
    marker_title: 'АВТО БРОКЕР',
    lat: 42.64285359546518,
    long: 23.405737084362286
  },
  name: 'Връзка с нас',
  address: {
    itemIcon: 'location_on',
    itemName: 'гр. София ул. Обиколна №37'
  },
  contactIcon: 'contacts',
  contactName: 'Контакти',
  phones: [
    {
      itemIcon: 'phone',
      name: 'Автосервиз',
      image: {
        path: 'assets/carservice/iAutoService-tb.png',
        _alt: 'iAutoService',
        type: 'route',
        link: routes.carservice.route
      },
      show: true,
      phone: '0893448080',
      url: {
        text: 'Запази час',
        icon: 'book_online',
        https: 'https://autobroker.ecar.bg/book'
      },
      worktime: {
        show: true,
        text: [
          {
            text: 'предстоящо откриване - Март 2020г.',
            hidden: true
          },
          {
            text: 'понеделник-събота от 09:00 до 18:00 часа',
            hidden: false
          },
          {
            text: 'събота от 09:00 до 15:00 часа',
            hidden: true
          }
        ]
      }
    },
    {
      itemIcon: 'phone',
      name: 'Автокъща',
      image: {
        path: 'assets/car-dealership/iAuto-tb.png',
        _alt: 'iAuto',
        type: 'url',
        link: 'iauto.mobile.bg'
      },
      show: true,
      phone: '0885610810',
      web: {
        itemIcon: 'language',
        www: 'iauto.mobile.bg'
      },
      worktime: {
        show: true,
        text: [
          {
            text: 'понеделник-петък от 9:00 до 17:00 часа',
            hidden: false
          },
          {
            text: 'извън работно време - с предварителна уговорка',
            hidden: false
          }
        ]
      }
    },
    {
      itemIcon: 'phone',
      name: 'Лизинг',
      image: {
        path: 'assets/iLeasing-tb.png',
        _alt: 'iLeasing',
        type: 'url',
        link: 'ileasing.eu'
      },
      show: true,
      phone: '0886900306',
      web: {
        itemIcon: 'language',
        www: 'ileasing.eu'
      },
      worktime: {
        show: true,
        text: [
          {
            text: 'понеделник-петък от 9:00 до 17:00 часа',
            hidden: false
          },
          {
            text: 'събота и неделя',
            hidden: true
          }
        ]
      }
    },    
    {
      itemIcon: 'phone',
      name: 'Офис',
      image: {
        path: 'assets/AutoBroker-tb.png',
        _alt: 'Автоброкер',
        type: 'route',
        link: routes.home.route
      },
      show: true,
      phone: '0885610810',
      worktime: {
        show: true,
        text: [
          {
            text: 'понеделник-петък от 9:00 до 17:00 часа',
            hidden: false
          },
          {
            text: 'събота и неделя',
            hidden: true
          }
        ]
      }
    }
  ],
  email: {
    itemIcon: 'alternate_email',
    email: 'info@autobroker.bg'
  },
  worktime: {
    itemIcon: 'access_time',
    itemName: 'Работно време',
    rows: [
      {
        name: 'Автосервиз:',
        show: true,
        text: [
          {
            text: 'предстоящо откриване - Март 2020г.',
            hidden: true
          },
          {
            text: 'понеделник-петък от 09:00 до 17:30 часа',
            hidden: false
          },
          {
            text: 'събота от 09:00 до 15:00 часа',
            hidden: true
          }
        ]
      },
      {
        name: 'Автокъща:',
        show: true,
        text: [
          {
            text: 'понеделник-петък от 9:00 до 17:00 часа',
            hidden: false
          },
          {
            text: 'извън работно време - с предварителна уговорка',
            hidden: false
          }
        ]
      },
      {
        name: 'Офис:',
        show: true,
        text: [
          {
            text: 'понеделник-петък от 9:00 до 17:00 часа',
            hidden: false
          },
          {
            text: 'събота и неделя',
            hidden: true
          }
        ]
      }
    ]
  }
};

