<div class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem" fxLayout="column">
  <b 
    class="font-2-0-rem" 
    fxFlex fxLayout="row" fxLayoutAlign="center" 
    style="padding-bottom: 2rem; text-align: center;"
  >
    {{inquiryConfig.title}}
  </b>

  <div fxLayout="column">
    <div *ngIf="submitted" fxLayout="row" fxLayoutAlign="center">
      <i class="font-2-0-rem" style="color: limegreen;">{{inquiryConfig.submitted.text}}</i>
    </div>

    <div *ngIf="!submitted" fxLayout="column">
      <form [formGroup]="inquiryForm!" autocomplete="off" novalidate (ngSubmit)="sendInquiry()">
        <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="1rem grid" style="padding-bottom: 1rem;">
          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <input matInput type="text" placeholder="{{inquiryConfig.name.placeholder}}" formControlName="name" [(ngModel)]="inquiry!.name" id="name">
            <button type="button" mat-button *ngIf="controlName?.value?.length>inquiryConfig.name.min_length" matSuffix mat-icon-button aria-label="Clear" (click)="inquiry!.name=''" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <mat-hint align="start">{{inquiryConfig.name.hint}}</mat-hint>
            <mat-hint align="end">{{controlName?.value?.length}}/{{inquiryConfig.name.max_length}}</mat-hint>
            <mat-error *ngIf="hasError('name', 'required')">{{inquiryConfig.required}}</mat-error>
            <mat-error *ngIf="hasError('name', 'minlength')">{{inquiryConfig.name.min_err_hint}}</mat-error>
            <mat-error *ngIf="hasError('name', 'maxlength')">{{inquiryConfig.name.max_err_hint}}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <input matInput type="text" placeholder="{{inquiryConfig.lastname.placeholder}}" formControlName="lastname" [(ngModel)]="inquiry!.lastname" id="lastname">
            <button type="button" mat-button *ngIf="controlLastName?.value?.length>inquiryConfig.lastname.min_length" matSuffix mat-icon-button aria-label="Clear" (click)="inquiry!.lastname=''" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <mat-hint align="start">{{inquiryConfig.lastname.hint}}</mat-hint>
            <mat-hint align="end">{{controlLastName?.value?.length}}/{{inquiryConfig.lastname.max_length}}</mat-hint>
            <mat-error *ngIf="hasError('lastname', 'required')">{{inquiryConfig.required}}</mat-error>
            <mat-error *ngIf="hasError('lastname', 'minlength')">{{inquiryConfig.lastname.min_err_hint}}</mat-error>
            <mat-error *ngIf="hasError('lastname', 'maxlength')">{{inquiryConfig.lastname.max_err_hint}}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="1rem grid" style="padding-bottom: 1rem;">
          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <input matInput type="text" placeholder="{{inquiryConfig.phone.placeholder}}" formControlName="phone" [(ngModel)]="inquiry!.phone" id="phone">
            <button type="button" mat-button *ngIf="controlPhone?.value?.length>inquiryConfig.name.min_length" matSuffix mat-icon-button aria-label="Clear" (click)="inquiry!.phone=''" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <mat-hint align="start">{{inquiryConfig.phone.hint}}</mat-hint>
            <mat-hint align="end">{{controlPhone?.value?.length}}/{{inquiryConfig.name.max_length}}</mat-hint>
            <mat-error *ngIf="hasError('phone', 'required')">{{inquiryConfig.required}}</mat-error>
            <mat-error *ngIf="hasError('phone', 'wrongNumber') && (controlPhone?.value?.length > 0)">{{inquiryConfig.phone.wrongNumber_err_hint}}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <input matInput type="email" placeholder="{{inquiryConfig.email.placeholder}}" formControlName="email" [(ngModel)]="inquiry!.email" id="email" email>
            <button type="button" mat-button *ngIf="controlEmail?.value?.length>inquiryConfig.lastname.min_length" matSuffix mat-icon-button aria-label="Clear" (click)="inquiry!.email=''" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <mat-hint align="start">{{inquiryConfig.email.hint}}</mat-hint>
            <mat-hint align="end">{{controlEmail?.value?.length}}/{{inquiryConfig.lastname.max_length}}</mat-hint>
            <mat-error *ngIf="hasError('email', 'required')">{{inquiryConfig.required}}</mat-error>
            <mat-error *ngIf="hasError('email', 'email') || hasError('email', 'pattern')">{{inquiryConfig.email.email_err_hint}}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="1rem grid" style="padding-bottom: 1rem;">
          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <mat-label>{{inquiryConfig.contactto.label}}</mat-label>
            <mat-select formControlName="contactto" [(ngModel)]="inquiry!.contactto" id="contactto">
              <mat-option *ngFor="let item of inquiryConfig.contactto.items" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select>
            <mat-hint>{{inquiryConfig.contactto.hint}}</mat-hint>
            <mat-error *ngIf="hasError('contactto', 'required')">{{inquiryConfig.required}}</mat-error>
          </mat-form-field>
      
          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <mat-label>{{inquiryConfig.sendto.label}}</mat-label>
            <mat-select formControlName="sendto" [(ngModel)]="inquiry!.sendto" id="sendto">
              <mat-option *ngFor="let item of inquiryConfig.sendto.items" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select>
            <mat-hint>{{inquiryConfig.sendto.hint}}</mat-hint>
            <mat-error *ngIf="hasError('sendto', 'required')">{{inquiryConfig.required}}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="1rem grid" style="padding-bottom: 1rem;" fxShow="{{carInquiry}}">
          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <input matInput type="text" placeholder="{{inquiryConfig.car.mark.placeholder}}" formControlName="carMark" [(ngModel)]="cardata!.mark" id="carMark">
            <button type="button" mat-button *ngIf="controlCarMark?.value?.length>inquiryConfig.car.mark.min_length" matSuffix mat-icon-button aria-label="Clear" (click)="cardata!.mark=''" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <mat-hint align="start">{{inquiryConfig.car.mark.hint}}</mat-hint>
            <mat-hint align="end">{{controlCarMark?.value?.length}}/{{inquiryConfig.car.mark.max_length}}</mat-hint>
            <mat-error *ngIf="hasError('carMark', 'required')">{{inquiryConfig.required}}</mat-error>
            <mat-error *ngIf="hasError('carMark', 'minlength')">{{inquiryConfig.car.mark.min_err_hint}}</mat-error>
            <mat-error *ngIf="hasError('carMark', 'maxlength')">{{inquiryConfig.car.mark.max_err_hint}}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <input matInput type="text" placeholder="{{inquiryConfig.car.model.placeholder}}" formControlName="carModel" [(ngModel)]="cardata!.model" id="carModel">
            <button type="button" mat-button *ngIf="controlCarModel?.value?.length>inquiryConfig.car.model.min_length" matSuffix mat-icon-button aria-label="Clear" (click)="cardata!.model=''" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <mat-hint align="start">{{inquiryConfig.car.model.hint}}</mat-hint>
            <mat-hint align="end">{{controlCarModel?.value?.length}}/{{inquiryConfig.car.model.max_length}}</mat-hint>
            <mat-error *ngIf="hasError('carModel', 'required')">{{inquiryConfig.required}}</mat-error>
            <mat-error *ngIf="hasError('carModel', 'minlength')">{{inquiryConfig.car.model.min_err_hint}}</mat-error>
            <mat-error *ngIf="hasError('carModel', 'maxlength')">{{inquiryConfig.car.model.max_err_hint}}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <mat-label>{{inquiryConfig.car.fuel.label}}</mat-label>
            <mat-select formControlName="carFuel" [(ngModel)]="cardata!.fuel" id="carFuel">
              <mat-option *ngFor="let item of inquiryConfig.car.fuel.items" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select>
            <mat-hint>{{inquiryConfig.car.fuel.hint}}</mat-hint>
            <mat-error *ngIf="hasError('carFuel', 'required')">{{inquiryConfig.required}}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <mat-label>{{inquiryConfig.car.transmission.label}}</mat-label>
            <mat-select formControlName="carTransmission" [(ngModel)]="cardata!.transmission" id="carTransmission">
              <mat-option *ngFor="let item of inquiryConfig.car.transmission.items" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select>
            <mat-hint>{{inquiryConfig.car.transmission.hint}}</mat-hint>
            <mat-error *ngIf="hasError('carTransmission', 'required')">{{inquiryConfig.required}}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <mat-label>{{inquiryConfig.car.firstRegistration.label}}</mat-label>
            <mat-select formControlName="carFirstRegistration" [(ngModel)]="cardata!.firstRegistration" id="carFirstRegistration">
              <mat-option *ngFor="let item of carYearItems" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select>
            <mat-hint>{{inquiryConfig.car.firstRegistration.hint}}</mat-hint>
            <mat-error *ngIf="hasError('carFirstRegistration', 'required')">{{inquiryConfig.required}}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="1 0 300px" fxLayout.lt-sm="column">
            <input matInput type="text" placeholder="{{inquiryConfig.car.price.placeholder}}" formControlName="carPrice" [(ngModel)]="cardata!.price" id="carPrice">
            <button type="button" mat-button *ngIf="controlCarPrice?.value?.length>inquiryConfig.car.price.min_length" matSuffix mat-icon-button aria-label="Clear" (click)="cardata!.price=''" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <mat-hint align="start">{{inquiryConfig.car.price.hint}}</mat-hint>
            <mat-hint align="end">{{controlCarPrice?.value?.length}}/{{inquiryConfig.car.price.max_length}}</mat-hint>
            <mat-error *ngIf="hasError('carPrice', 'required')">{{inquiryConfig.required}}</mat-error>
            <mat-error *ngIf="hasError('carPrice', 'minlength')">{{inquiryConfig.car.price.min_err_hint}}</mat-error>
            <mat-error *ngIf="hasError('carPrice', 'maxlength')">{{inquiryConfig.car.price.max_err_hint}}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" style="padding-bottom: 3rem;">
          <mat-form-field fxFlex fxLayout.lt-sm="column">
            <textarea 
              matInput 
              placeholder="{{inquiryConfig.message.placeholder}}" 
              formControlName="message" 
              [(ngModel)]="inquiry!.message" 
              id="message" 
              cdkTextareaAutosize
              cdkAutosizeMinRows="{{inquiryConfig.message.min_rows}}"
              cdkAutosizeMaxRows="{{inquiryConfig.message.max_rows}}"
           >
            </textarea>
            <mat-hint align="start">{{inquiryConfig.message.hint}}</mat-hint>
            <mat-hint align="end">{{controlMessage?.value?.length}}/{{inquiryConfig.message.max_length}}</mat-hint>
            <mat-error *ngIf="hasError('message', 'required')">{{inquiryConfig.required}}</mat-error>
            <mat-error *ngIf="hasError('message', 'minlength')">{{inquiryConfig.message.min_err_hint}}</mat-error>
            <mat-error *ngIf="hasError('message', 'maxlength')">{{inquiryConfig.message.max_err_hint}}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="1rem grid" style="padding-bottom: 1rem;">
          <div fxFlex fxLayout.lt-sm="column" fxLayoutAlign="center center">
            <mat-checkbox 
              formControlName="agreement" 
              [(ngModel)]="inquiry!.agreement" 
              id="agreement"
              [(indeterminate)]="cbIndeterminate"
              [color]="inquiry?.agreement ? 'primary' : 'warn'" style="text-align: justify;">
              {{inquiryConfig.agreement.text}}
            </mat-checkbox>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center">
          <div fxFlex fxLayout="row" fxLayoutAlign="center center">
            <button type="submit" 
              class="font-1-3-rem" ngClass.gt-sm="font-1-5-rem" 
              fxFlex="62" 
              mat-raised-button 
              [disabled]="!inquiry?.agreement || inquiryForm?.invalid || submitted">
                {{inquiryConfig.submitButton.text}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>  
</div>